import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('@core').then((m) => m.UiTemplateComponent),
    children: [
      {
        path: '',
        loadChildren: () => import('@zendesk-donations').then((module) => module.zendeskDonationRoutes),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
